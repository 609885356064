<template>
  <div class="v-services">
    <div class="v-services_articles">
      <h2 v-if="numArticle === 1">Как не платить за ДТП на каршеринге</h2>
      <h2 v-if="numArticle === 2">Как оспорить штраф за опасное вождение на каршеринге (дрифт, шашки и т.д)</h2>
      <h2 v-if="numArticle === 3">Оспорить штраф за управление автомобилем каршеринга в нетрезвом виде</h2>
      <h2 v-if="numArticle === 4">Оспорить штраф за передачу управления автомобилем каршеринга</h2>
      <h2 v-if="numArticle === 5">Обжаловать штраф каршеринга за неправильную парковку</h2>
      <router-link tag="div" :class="{active: numArticle===articles[0]}" :to="{name: 'services', params: {id: articles[0]}}" class="articles-container">
        <div class="title">Как не платить за ДТП на каршеринге</div>
        <div class="subtitle"></div>
      </router-link>
      <router-link tag="div" :class="{active: numArticle===articles[1]}" :to="{name: 'services', params: {id: articles[1]}}" class="articles-container">
        <div class="title">Как оспорить штраф за опасное вождение на каршеринге (дрифт, шашки и т.д)</div>
        <div class="subtitle"></div>
      </router-link>
      <router-link tag="div" :class="{active: numArticle===articles[2]}" :to="{name: 'services', params: {id: articles[2]}}" class="articles-container">
        <div class="title">Оспорить штраф за управление автомобилем каршеринга в нетрезвом виде</div>
        <div class="subtitle"></div>
      </router-link>
      <router-link tag="div" :class="{active: numArticle===articles[3]}" :to="{name: 'services', params: {id: articles[3]}}" class="articles-container">
        <div class="title">Оспорить штраф за передачу управления автомобилем каршеринга</div>
        <div class="subtitle"></div>
      </router-link>
      <router-link tag="div" :class="{active: numArticle===articles[4]}" :to="{name: 'services', params: {id: articles[4]}}" class="articles-container">
        <div class="title">Обжаловать штраф каршеринга за неправильную парковку</div>
        <div class="subtitle"></div>
      </router-link>
    </div>
    <div class="v-services_quest" v-if="numArticle === 1">
      <div class="v-services_quest-container" v-for="quest in questions1" :key="quest.id">
        <span>{{quest.value}}</span>
        <div class="answer-wrapper">
          <label :class="{active: quest.answer === quest.valueAnswerTrue}"><input hidden type="radio"  :value="quest.valueAnswerTrue" v-model="quest.answer" class="answer-1">{{quest.valueAnswerTrue}}</label>
          <label :class="{active: quest.answer === quest.valueAnswerFalse}"><input hidden type="radio"  :value="quest.valueAnswerFalse" v-model="quest.answer" class="answer-2">{{quest.valueAnswerFalse}}</label>
        </div>
      </div>
    </div>
    <div class="v-services_quest" v-if="numArticle === 2">
      <div class="v-services_quest-container" v-for="quest in questions2" :key="quest.id">
        <span>{{quest.value}}</span>
        <div class="answer-wrapper">
          <label :class="{active: quest.answer === quest.valueAnswerTrue}"><input hidden type="radio"  :value="quest.valueAnswerTrue" v-model="quest.answer" class="answer-1">{{quest.valueAnswerTrue}}</label>
          <label :class="{active: quest.answer === quest.valueAnswerFalse}"><input hidden type="radio"  :value="quest.valueAnswerFalse" v-model="quest.answer" class="answer-2">{{quest.valueAnswerFalse}}</label>
        </div>
      </div>
    </div>
    <div class="v-services_quest" v-if="numArticle === 3">
      <div class="v-services_quest-container" v-for="quest in questions3" :key="quest.id">
        <span>{{quest.value}}</span>
        <div class="answer-wrapper">
          <label :class="{active: quest.answer === quest.valueAnswerTrue}"><input hidden type="radio"  :value="quest.valueAnswerTrue" v-model="quest.answer" class="answer-1">{{quest.valueAnswerTrue}}</label>
          <label :class="{active: quest.answer === quest.valueAnswerFalse}"><input hidden type="radio"  :value="quest.valueAnswerFalse" v-model="quest.answer" class="answer-2">{{quest.valueAnswerFalse}}</label>
        </div>
      </div>
    </div>
    <div class="v-services_quest" v-if="numArticle === 4">
      <div class="v-services_quest-container" v-for="quest in questions4" :key="quest.id">
        <span>{{quest.value}}</span>
        <div class="answer-wrapper">
          <label :class="{active: quest.answer === quest.valueAnswerTrue}"><input hidden type="radio"  :value="quest.valueAnswerTrue" v-model="quest.answer" class="answer-1">{{quest.valueAnswerTrue}}</label>
          <label :class="{active: quest.answer === quest.valueAnswerFalse}"><input hidden type="radio"  :value="quest.valueAnswerFalse" v-model="quest.answer" class="answer-2">{{quest.valueAnswerFalse}}</label>
        </div>
      </div>
    </div>
    <div class="v-services_quest" v-if="numArticle === 5">
      <div class="v-services_quest-container" v-for="quest in questions5" :key="quest.id">
        <span>{{quest.value}}</span>
        <div class="answer-wrapper">
          <label :class="{active: quest.answer === quest.valueAnswerTrue}"><input hidden type="radio"  :value="quest.valueAnswerTrue" v-model="quest.answer" class="answer-1">{{quest.valueAnswerTrue}}</label>
          <label :class="{active: quest.answer === quest.valueAnswerFalse}"><input hidden type="radio"  :value="quest.valueAnswerFalse" v-model="quest.answer" class="answer-2">{{quest.valueAnswerFalse}}</label>
        </div>
      </div>
    </div>
    <div ref="form" class="v-services_form">
      <div class="title">
        ВВЕДИТЕ ДАННЫЕ
        <p>Получите результаты проверки информации по базе судебных актов</p>
      </div>
      <form>
        <div class="form-wrapper">
          <label>
            <span>Ваше имя</span>
            <input type="text" v-model="name" placeholder="Введите имя">
          </label>
          <label>
            <span>E-mail</span>
            <input type="email" v-model="email" placeholder="E-mail">
          </label>
          <label>
            <span>Телефон</span>
            <input type="text" class="new-input" required placeholder="Ваш телефон" data-inputmask="'mask': '+9(999)999-99-99'"  v-model="phone">
          </label>
        </div>
      </form>
      <button type="submit" @click="outMessUrist" onclick="ym(72714331,'reachGoal','order')">Узнать результаты</button>
      <p>Отправляя форму вы даёте согласие на обработку персональных данных</p>
    </div>
    <div id="answer" style="display: none" class="v-services_answer" v-if="numArticle === 1">
      <p>Добрый день!</p>
      <p>
        Направляем Вам предварительные результаты, основанные на тщательном анализе постановлений и решений по аналогичным делам. Искусственный интеллект
        Наши юристы готовы помочь Вам в том числе лично и по телефону предоставив бесплатную консультацию.
      </p>
      <p>
        Наши юристы готовы помочь Вам в том числе лично и по телефону предоставив бесплатную консультацию.
        Записаться на консультацию можно кликнув <a href="https://avtourist.net">здесь</a>
      </p>
      <p>А теперь по Вашему делу:</p>
      <div class="answer-wrapper">
        <div class="answer-container">
          <div v-if="questions1[0].answer === questions1[0].valueAnswerTrue" class="title">Если Вы управляли автомобилем.</div>
          <div v-else class="title">Если Вы не управляли автомобилем.</div>
          <div v-if="questions1[0].answer === questions1[0].valueAnswerTrue" class="subtitle">
            Если Вы управляли автомобилем в момент ДТП необходимо проверить законность штрафа, т.к. размер штрафа варьируется в зависимости от того, кто виновен в ДТП, от размера ущерба, причиненного автомобилю и других обстоятельств          </div>
          <div v-else class="subtitle">
            Если Вы не управляли автомобилем во время ДТП необходимо доказать свою невиновность, что не всегда удается простым гражданам, поэтому наши юристы Вам помогут на всех стадиях дела.
          </div>
        </div>
        <div class="answer-container">
          <div v-if="questions1[1].answer === questions1[1].valueAnswerTrue" class="title">Если есть фотографии до поездки и после.</div>
          <div v-else class="title">Фотографии.</div>
          <div v-if="questions1[1].answer === questions1[1].valueAnswerTrue" class="subtitle">
            Если есть фотографии до поездки и после, то при обжаловании штрафа не должно возникнуть особых сложностей. По статистике 84% наших клиентов, у которых есть фото-доказательства выигрывают дела
          </div>
          <div v-else class="subtitle">
            Фотографии со стороны водителя могут и не быть. Наличие фотографий необязательно, главное- найти другие доказательства, которые помогут выиграть дело.
          </div>
        </div>
        <div class="answer-container">
          <div v-if="questions1[2].answer === questions1[2].valueAnswerTrue" class="title">Наличие свидетелей.</div>
          <div v-else class="title">Наличие свидетелей.</div>
          <div v-if="questions1[2].answer === questions1[2].valueAnswerTrue" class="subtitle">
            Наличие свидетелей будет большим плюсом, так как с помощью их показаний можно выиграть дело. Обсудите с юристом сложность Вашего дела для того, чтобы привлечь их для выступления в суде. Юрист подготовит для них речь.
          </div>
          <div v-else class="subtitle">
            Наличие свидетелей со стороны участников ДТП необязательно. Вашу невиновность будем доказывать на основании других доказательств
          </div>
        </div>
        <div class="answer-container">
          <div v-if="questions1[3].answer === questions1[3].valueAnswerTrue" class="title">Скрывались с места ДТП.</div>
          <div v-else class="title">Не скрывались с места ДТП.</div>
          <div v-if="questions1[3].answer === questions1[3].valueAnswerTrue" class="subtitle">
            Если были пострадавшие или второй участник ДТП Вы обязаны были подождать приезда сотрудников ГИБДД, а если уехали, оставив место ДТП, в отношении Вас выносят постановление по ст. 12.27, с обжалованием которого помогут наши юристы с многолетним опытом работы по аналогичным делам.
          </div>
          <div v-else class="subtitle">
            Если не скрывались с места ДТП будет намного легче выиграть дело. Большинство клиентов нашей компании, которые не скрывались с места ДТП, оспорили постановление.
          </div>
        </div>
        <div class="answer-container">
          <div v-if="questions1[4].answer === questions1[4].valueAnswerTrue" class="title">Вы единственный участник ДТП.</div>
          <div v-else class="title">Несколько участников ДТП.</div>
          <div v-if="questions1[4].answer === questions1[4].valueAnswerTrue" class="subtitle">
            Если Вы единственный участник ДТП, то Вам не нужно будет платить кому-либо за причиненный ущерб и нам будет легче обжаловать штраф
          </div>
          <div v-else class="subtitle">
            Если было несколько участников ДТП необходимо доказать вашу невиновность, поскольку водитель, который не виноват в ДТП не платит штраф. Однако, не всегда это удается сделать самостоятельно, поэтому необходимо подготовить грамотную правовую позицию с помощью юриста
          </div>
        </div>
        <div class="answer-container">
          <div v-if="questions1[5].answer === questions1[5].valueAnswerTrue" class="title">Вы являетесь виновником ДТП.</div>
          <div v-else class="title">Вы не являетесь виновником ДТПе.</div>
          <div v-if="questions1[5].answer === questions1[5].valueAnswerTrue" class="subtitle">
            Если Вы являетесь виновником ДТП нужно постараться доказать обратное, так как водитель, который не виноват в ДТП не платит штраф. По статистике 13% виновников ДТП в России устанавливаются неправильно
          </div>
          <div v-else class="subtitle">
            Если не являетесь виновником ДТП, Вы не должны платить штраф. А если все-таки списали деньги или пришел штраф, то его нужно обжаловать.
          </div>
        </div>
        <div class="answer-container">
          <div v-if="questions1[6].answer === questions1[6].valueAnswerTrue" class="title">С карты списали деньги.</div>
          <div v-else class="title">Деньги не были списаны.</div>
          <div v-if="questions1[6].answer === questions1[6].valueAnswerTrue" class="subtitle">
            Если с вашей карты списали деньги нужно писать претензию оператору каршеринга, при отказе в удовлетворении которой подается иск в суд. Главное- правильно составить все документы со ссылками на нормы права и правила аренды. 86% наших клиентов уже вернули незаконно списанные деньги каршерингом.
          </div>
          <div v-else class="subtitle">
            Если деньги не были списаны с карты оспорить штраф будет легче и быстрее, но основные процедуры не отменяются
          </div>
        </div>
      </div>
      <h5>Решать споры с оператором каршеринга – это наша ежедневная работа. И мы делаем ее действительно хорошо!</h5>
      <h3>
        С уважением,
        <br>
        Команда Автоюрист
      </h3>
    </div>
    <div id="answer" style="display: none" class="v-services_answer" v-if="numArticle === 2">
      <p>Добрый день!</p>
      <p>
        Направляем Вам предварительные результаты, основанные на тщательном анализе постановлений и решений по аналогичным делам. Искусственный интеллект
        Наши юристы готовы помочь Вам в том числе лично и по телефону предоставив бесплатную консультацию.
      </p>
      <p>
        Наши юристы готовы помочь Вам в том числе лично и по телефону предоставив бесплатную консультацию.
        Записаться на консультацию можно кликнув <a href="https://avtourist.net">здесь</a>
      </p>
      <p>А теперь по Вашему делу:</p>
      <div class="answer-wrapper">
        <div class="answer-container">
          <div v-if="questions2[0].answer === questions2[0].valueAnswerTrue" class="title">Вы нарушали ПДД.</div>
          <div v-else class="title">Вы не нарушали ПДД.</div>
          <div v-if="questions2[0].answer === questions2[0].valueAnswerTrue" class="subtitle">
            Если Вы нарушали ПДД, то будет сложнее обжаловать штраф, но мы сможем сделать это, оспорив доказательства, имеющиеся в материалах дела. По статистике 21% административных дел составляются с нарушением норм права, соответственно правонарушители по этим делам признаются невиновными, а штрафы-отменяются
          </div>
          <div v-else class="subtitle">
            Если Вы не нарушали ПДД, то доказательств того, что использовали автомобиль не по назначению, будет меньше, а оспорить штраф-намного легче
          </div>
        </div>
        <div class="answer-container">
          <div v-if="questions2[1].answer === questions2[1].valueAnswerTrue" class="title">Если есть доказательства.</div>
          <div v-else class="title">Отсутствие доказательств.</div>
          <div v-if="questions2[1].answer === questions2[1].valueAnswerTrue" class="subtitle">
            Если есть доказательства, то их нужно оспаривать. Все доказательства должны соответствовать определенным требованиям, таким как относимость, допустимость, достоверность и т.д. При несоответствии доказательств этим требованиям их следует исключить из материалов дела, что поможет выиграть дело
          </div>
          <div v-else class="subtitle">
            Отсутствие доказательств будет большим преимуществом, так как без доказательственной базы не может быть вынесено обвинительное решение. 97% из всех решений, вынесенных без доказательств, были отменены
          </div>
        </div>
        <div class="answer-container">
          <div v-if="questions2[2].answer === questions2[2].valueAnswerTrue" class="title">Если есть доказательства.</div>
          <div v-else class="title">Доказательства.</div>
          <div v-if="questions2[2].answer === questions2[2].valueAnswerTrue" class="subtitle">
            Если есть доказательства того, что Вы использовали автомобиль по назначению, не совершали дрифт, шашки и
            другие нарушения, то выиграть дело не составит труда, главное-правильно предоставить эти доказательства в суд
          </div>
          <div v-else class="subtitle">
            Доказательства со стороны водителя могут и не быть. Наличие доказательств необязательно, главное-найти
            ошибки в доказательствах, предоставленных оператором каршеринга и исключить их из материалов дела
          </div>
        </div>
        <div class="answer-container">
          <div v-if="questions2[3].answer === questions2[3].valueAnswerTrue" class="title">Если получили претензию.</div>
          <div v-else class="title">Не получили претензию.</div>
          <div v-if="questions2[3].answer === questions2[3].valueAnswerTrue" class="subtitle">
            Если получили претензию от оператора каршеринга, то необходимо написать на нее отзыв с обоснованной правовой позицией и ссылками на договор аренды
          </div>
          <div v-else class="subtitle">
            Компании каршеринга не всегда отправляют претензию и они не обязаны делать это
          </div>
        </div>
        <div class="answer-container">
          <div v-if="questions2[4].answer === questions2[4].valueAnswerTrue" class="title">С карты списали деньги.</div>
          <div v-else class="title">Деньги не были списаны.</div>
          <div v-if="questions2[4].answer === questions2[4].valueAnswerTrue" class="subtitle">
            Если с вашей карты списали деньги нужно писать претензию оператору каршеринга, при отказе в удовлетворении
            которой подается иск в суд. Главное- правильно составить все документы со ссылками на нормы права и
            правила аренды. 86% наших клиентов уже вернули незаконно списанные деньги каршерингом.
          </div>
          <div v-else class="subtitle">
            Если деньги не были списаны с карты оспорить штраф будет легче и быстрее, но основные процедуры не отменяются
          </div>
        </div>
      </div>
      <h5>Решать споры с оператором каршеринга – это наша ежедневная работа. И мы делаем ее действительно хорошо!</h5>
      <h3>
        С уважением,
        <br>
        Команда Автоюрист
      </h3>
    </div>
    <div id="answer" style="display: none" class="v-services_answer" v-if="numArticle === 3">
      <p>Добрый день!</p>
      <p>
        Направляем Вам предварительные результаты, основанные на тщательном анализе постановлений и решений по аналогичным делам. Искусственный интеллект
        Наши юристы готовы помочь Вам в том числе лично и по телефону предоставив бесплатную консультацию.
      </p>
      <p>
        Наши юристы готовы помочь Вам в том числе лично и по телефону предоставив бесплатную консультацию.
        Записаться на консультацию можно кликнув <a href="https://avtourist.net">здесь</a>
      </p>
      <p>А теперь по Вашему делу:</p>
      <div class="answer-wrapper">
        <div class="answer-container">
          <div v-if="questions3[0].answer === questions3[0].valueAnswerTrue" class="title">Управляли автомобилем в состоянии опьянения.</div>
          <div v-else class="title">Не управляли автомобилем в состоянии опьянения.</div>
          <div v-if="questions3[0].answer === questions3[0].valueAnswerTrue" class="subtitle">
            Если управляли автомобилем в состоянии алкогольного/наркотического опьянения и данный факт был зафиксирован
            сотрудниками ГИБДД, то нужно оспаривать доказательства, имеющиеся в материалах дела. По статистике 21%
            административных дел составляются с нарушением норм права, соответственно штрафы по этим делам отменяются, а правонарушители признаются невиновными
          </div>
          <div v-else class="subtitle">
            Если не управляли автомобилем в состоянии алкогольного/наркотического опьянения, но в отношении Вас
            составлен административный материал по ст. 12.8 КоАП РФ нужно признать доказательства недействительными и исключить их из материалов дела, что поможет выиграть дело
          </div>
        </div>
        <div class="answer-container">
          <div v-if="questions3[1].answer === questions3[1].valueAnswerTrue" class="title">Вынесено постановление.</div>
          <div v-else class="title">Не признаны виновным.</div>
          <div v-if="questions3[1].answer === questions3[1].valueAnswerTrue" class="subtitle">
            Если в отношении Вас вынесено постановление по делу об административном правонарушении, которым Вы признаны виновным по ч.1
            ст.12.8 КоАП РФ, то его нужно обжаловать, оспорив акт (мед-) освидетельствования, протокол об АП и другие доказательства.
          </div>
          <div v-else class="subtitle">
            Если не признаны виновным по ст. 12.8 КоАП РФ, то компания каршеринга не имеет право снимать денежные
            средства с вашей карты в качестве штрафа. А если все-таки наложен штраф, то наши юристы с многолетним опытом помогут его оспорить
          </div>
        </div>
        <div class="answer-container">
          <div v-if="questions3[2].answer === questions3[2].valueAnswerTrue" class="title">Свидетели.</div>
          <div v-else class="title">Свидетели.</div>
          <div v-if="questions3[2].answer === questions3[2].valueAnswerTrue" class="subtitle">
            Свидетели. Как правило, это, например, водители авто, которых остановили вместе с Вами или другие граждане,
            а также может быть привлечен второй инспектор ДПС. Привлечение инспектора ДПС является нарушением КоАП,
            но главной сложностью является то, что суды не обращают на это внимание и водители не могут самостоятельно выиграть дело.
          </div>
          <div v-else class="subtitle">
            Свидетели. То, что в протокол не вписаны свидетели немного упрощает нашу работу, но не исключает проведение всех остальных действий.
          </div>
        </div>
        <div class="answer-container">
          <div v-if="questions3[3].answer === questions3[3].valueAnswerTrue" class="title">Участие понятых.</div>
          <div v-else class="title">Видео.</div>
          <div v-if="questions3[3].answer === questions3[3].valueAnswerTrue" class="subtitle">
            Участие понятых при составлении административного материала регламентировано КоАП РФ. А в случае нарушения
            его норм можно исключить объяснения понятых из доказательств. Эта процедура сложная, но мы знаем,
            как отменить штраф и постановление, найдя ошибки в объяснениях понятых.
          </div>
          <div v-else class="subtitle">
            Проверьте в протоколе (если он у Вас есть на руках), указано ли то, что к нему прилагается видео.
            Если такого указания нет или не указано каким техническим средством она была произведена – видеозапись
            подлежит исключению из доказательств. Также еще есть несколько требований к видеозаписи, при нарушении
            которых можно исключить видеозапись, что могут сделать наши юристы.
          </div>
        </div>
        <div class="answer-container">
          <div v-if="questions3[4].answer === questions3[4].valueAnswerTrue" class="title">Списали деньги</div>
          <div v-else class="title">Деньги не были списаны.</div>
          <div v-if="questions3[4].answer === questions3[4].valueAnswerTrue" class="subtitle">
            Если с вашей карты списали деньги нужно писать претензию оператору каршеринга, при отказе в удовлетворении
            которой подается иск в суд. Главное- правильно составить все документы со ссылками на нормы права
            и правила аренды. 86% наших клиентов уже вернули незаконно списанные деньги каршерингом.
          </div>
          <div v-else class="subtitle">
            Если деньги не были списаны с карты оспорить штраф будет легче и быстрее, но основные процедуры не отменяются
          </div>
        </div>
      </div>
      <h5>Решать споры с оператором каршеринга – это наша ежедневная работа. И мы делаем ее действительно хорошо!</h5>
      <h3>
        С уважением,
        <br>
        Команда Автоюрист
      </h3>
    </div>
    <div id="answer" style="display: none" class="v-services_answer" v-if="numArticle === 4">
      <p>Добрый день!</p>
      <p>
        Направляем Вам предварительные результаты, основанные на тщательном анализе постановлений и решений по аналогичным делам. Искусственный интеллект
        Наши юристы готовы помочь Вам в том числе лично и по телефону предоставив бесплатную консультацию.
      </p>
      <p>
        Наши юристы готовы помочь Вам в том числе лично и по телефону предоставив бесплатную консультацию.
        Записаться на консультацию можно кликнув <a href="https://avtourist.net">здесь</a>
      </p>
      <p>А теперь по Вашему делу:</p>
      <div class="answer-wrapper">
        <div class="answer-container">
        <div v-if="questions4[0].answer === questions4[0].valueAnswerTrue" class="title">Передача руля.</div>
        <div v-else class="title">Не передавали управление.</div>
        <div v-if="questions4[0].answer === questions4[0].valueAnswerTrue" class="subtitle">
          Передача руля другому лицу является нарушением правил аренды, за которое предусмотрен штраф, но даже
          если вы передавали управление можно обойтись без штрафа, оспорив доказательства и факт передачи руля, с чем Вам помогут наши юристы
        </div>
        <div v-else class="subtitle">
          Если не передавали управление автомобилем, то оператор каршеринга не имеет право наложить штраф,
          а если все-таки получили штраф его можно оспорить, главное-правильно составить необходимые документы
        </div>
      </div>
        <div class="answer-container">
          <div v-if="questions4[1].answer === questions4[1].valueAnswerTrue" class="title">Есть доказательства.</div>
          <div v-else class="title">Отсутствие доказательств.</div>
          <div v-if="questions4[1].answer === questions4[1].valueAnswerTrue" class="subtitle">
            Если есть доказательства, то их нужно оспаривать. Все доказательства должны соответствовать определенным
            требованиям, таким как относимость, допустимость, достоверность и т.д. При несоответствии доказательств
            этим требованиям их следует исключить из материалов дела, что поможет выиграть дело
          </div>
          <div v-else class="subtitle">
            Отсутствие доказательств будет большим преимуществом, так как без доказательственной
            базы не может быть вынесено обвинительное решение. 97% из всех решений, вынесенных без доказательств, были отменены
          </div>
        </div>
        <div class="answer-container">
          <div v-if="questions4[2].answer === questions4[2].valueAnswerTrue" class="title">Наличие доказательств.</div>
          <div v-else class="title">Доказательства со стороны водителя.</div>
          <div v-if="questions4[2].answer === questions4[2].valueAnswerTrue" class="subtitle">
            Наличие доказательств будет хорошим преимуществом и упростит задачу, но к ним также предъявляются требования, в соответствии с которыми они предоставляются в суд
          </div>
          <div v-else class="subtitle">
            Доказательства со стороны водителя могут и не быть. Наличие доказательств необязательно, главное-найти ошибки в доказательствах, предоставленных оператором каршеринга и исключить их из материалов дела
          </div>
        </div>
        <div class="answer-container">
          <div v-if="questions4[3].answer === questions4[3].valueAnswerTrue" class="title">Если получили претензию.</div>
          <div v-else class="title">Если получили претензию.</div>
          <div v-if="questions4[3].answer === questions4[3].valueAnswerTrue" class="subtitle">
            Если получили претензию от оператора каршеринга, то необходимо написать на нее отзыв с обоснованной правовой позицией и ссылками на договор аренды
          </div>
          <div v-else class="subtitle">
            Компании каршеринга не всегда отправляют претензию и они не обязаны делать это
          </div>
        </div>
        <div class="answer-container">
          <div v-if="questions4[4].answer === questions4[4].valueAnswerTrue" class="title">Списали деньги.</div>
          <div v-else class="title">Деньги не списаны.</div>
          <div v-if="questions4[4].answer === questions4[4].valueAnswerTrue" class="subtitle">
            Если с вашей карты списали деньги нужно писать претензию оператору каршеринга, при отказе в удовлетворении
            которой подается иск в суд. Главное- правильно составить все документы со ссылками на нормы права и правила аренды. 86% наших клиентов уже вернули незаконно списанные деньги каршерингом.
          </div>
          <div v-else class="subtitle">
            Если деньги не были списаны с карты оспорить штраф будет легче и быстрее, но основные процедуры не отменяются
          </div>
        </div>
      </div>
      <h5>Решать споры с оператором каршеринга – это наша ежедневная работа. И мы делаем ее действительно хорошо!</h5>
      <h3>
        С уважением,
        <br>
        Команда Автоюрист
      </h3>
    </div>
    <div id="answer" style="display: none" class="v-services_answer" v-if="numArticle === 5">
      <p>Добрый день!</p>
      <p>
        Направляем Вам предварительные результаты, основанные на тщательном анализе постановлений и решений по аналогичным делам. Искусственный интеллект
        Наши юристы готовы помочь Вам в том числе лично и по телефону предоставив бесплатную консультацию.
      </p>
      <p>
        Наши юристы готовы помочь Вам в том числе лично и по телефону предоставив бесплатную консультацию.
        Записаться на консультацию можно кликнув <a href="https://avtourist.net">здесь</a>
      </p>
      <p>А теперь по Вашему делу:</p>
      <div class="answer-wrapper">
        <div class="answer-container">
          <div v-if="questions5[0].answer === questions5[0].valueAnswerTrue" class="title">Если вынесено постановление.</div>
          <div v-else class="title">Если не вынесено постановление.</div>
          <div v-if="questions5[0].answer === questions5[0].valueAnswerTrue" class="subtitle">
            Если вынесено постановление по делу об административном правонарушении, его нужно обжаловать,
            оспорив доказательства, имеющиеся в материалах дела. По статистическим данным 32% из всех доказательств не соответствуют требованиям законодательства
          </div>
          <div v-else class="subtitle">
            Если не вынесено постановление по делу об АП, которым Вы признаны виновным в неправильной парковке,
            оператор каршеринга не имеет право назначить штраф. По статистике 12% из всех штрафов каршеринга за парковку выносятся незаконно
          </div>
        </div>
        <div class="answer-container">
          <div v-if="questions5[1].answer === questions5[1].valueAnswerTrue" class="title">Есть доказательства парковки.</div>
          <div v-else class="title">Отсутствие доказательств.</div>
          <div v-if="questions5[1].answer === questions5[1].valueAnswerTrue" class="subtitle">
            Если есть доказательства парковки в неположенном месте, то их нужно оспаривать. Все доказательства должны
            соответствовать определенным требованиям, таким как относимость, допустимость, достоверность и т.д.
            При несоответствии доказательств этим требованиям их следует исключить из материалов дела, что поможет выиграть дело
          </div>
          <div v-else class="subtitle">
            Отсутствие доказательств будет большим преимуществом, так как без
            доказательственной базы не может быть вынесено обвинительное решение. 97% из всех решений, вынесенных без доказательств, были отменены
          </div>
        </div>
        <div class="answer-container">
          <div v-if="questions5[2].answer === questions5[2].valueAnswerTrue" class="title">Получили претензию.</div>
          <div v-else class="title">Получили претензию.</div>
          <div v-if="questions5[2].answer === questions5[2].valueAnswerTrue" class="subtitle">
            Если получили претензию от оператора каршеринга, то необходимо написать на нее отзыв с обоснованной правовой позицией и ссылками на договор аренды
          </div>
          <div v-else class="subtitle">
            Компании каршеринга не всегда отправляют претензию и они не обязаны делать это
          </div>
        </div>
        <div class="answer-container">
          <div v-if="questions5[3].answer === questions5[3].valueAnswerTrue" class="title">Списали деньги.</div>
          <div v-else class="title">Списали деньги.</div>
          <div v-if="questions5[3].answer === questions5[3].valueAnswerTrue" class="subtitle">
            Если с вашей карты списали деньги нужно писать претензию оператору каршеринга, при отказе в удовлетворении
            которой подается иск в суд. Главное- правильно составить все документы со ссылками на нормы права и
            правила аренды. 86% наших клиентов уже вернули незаконно списанные деньги каршерингом.
          </div>
          <div v-else class="subtitle">
            Если деньги не были списаны с карты оспорить штраф будет легче и быстрее, но основные процедуры не отменяются
          </div>
        </div>
      </div>
      <h5>Решать споры с оператором каршеринга – это наша ежедневная работа. И мы делаем ее действительно хорошо!</h5>
      <h3>
        С уважением,
        <br>
        Команда Автоюрист
      </h3>
    </div>
    <notice
      v-if="isPopup"
      @removePopup="removePopup"
      :isPopup="isPopup"
      :title="title"
      :subtitle="subtitle"
    />
  </div>
</template>

<script>
import axios from 'axios'
import Inputmask from 'inputmask'
import notice from './notification'
export default {
  name: 'v-services',
  components: { notice },
  data () {
    return {
      articles: [
        1, 2, 3, 4, 5
      ],
      questions1: [
        { value: 'Вы управляли автомобилем во время ДТП?', answer: 1, valueAnswerTrue: 'Да', valueAnswerFalse: 'Нет', id: '1' },
        { value: 'Есть фото до и после поездки?', answer: 2, valueAnswerTrue: 'Да', valueAnswerFalse: 'Нет', id: '2' },
        { value: 'Были свидетели, в т.ч. пассажиры?', answer: 3, valueAnswerTrue: 'Да', valueAnswerFalse: 'Нет', id: '3' },
        { value: 'Скрывались с места ДТП?', answer: 4, valueAnswerTrue: 'Да', valueAnswerFalse: 'Нет', id: '4' },
        { value: 'Вы единственный участник ДТП?', answer: 5, valueAnswerTrue: 'Да', valueAnswerFalse: 'Нет', id: '5' },
        { value: 'Вы являетесь виновником ДТП?', answer: 6, valueAnswerTrue: 'Да', valueAnswerFalse: 'Нет', id: '6' },
        { value: 'Списаны ли деньги с Вашей карты?', answer: 7, valueAnswerTrue: 'Да', valueAnswerFalse: 'Нет', id: '7' }
      ],
      questions2: [
        { value: 'Вы нарушали ПДД?', answer: 1, valueAnswerTrue: 'Да', valueAnswerFalse: 'Нет', id: '1' },
        { value: 'Есть доказательства совершения Вами правонарушения?', answer: 2, valueAnswerTrue: 'Да', valueAnswerFalse: 'Нет', id: '2' },
        { value: 'У Вас есть доказательства того, что не совершали дрифт шашки и т.д?', answer: 3, valueAnswerTrue: 'Да', valueAnswerFalse: 'Нет', id: '3' },
        { value: 'Получили претензию от компании каршеринга?', answer: 4, valueAnswerTrue: 'Да', valueAnswerFalse: 'Нет', id: '4' },
        { value: 'Списаны деньги с Вашей карты?', answer: 5, valueAnswerTrue: 'Да', valueAnswerFalse: 'Нет', id: '5' }
      ],
      questions3: [
        { value: 'Управляли автомобилем в состоянии алко или нарко опьянения?', answer: 1, valueAnswerTrue: 'Да', valueAnswerFalse: 'Нет', id: '1' },
        { value: 'Вы признаны виновным по ст.12.8 КоАП РФ?', answer: 2, valueAnswerTrue: 'Да', valueAnswerFalse: 'Нет', id: '2' },
        { value: 'Были свидетели?', answer: 3, valueAnswerTrue: 'Да', valueAnswerFalse: 'Нет', id: '3' },
        { value: 'При (мед-)освидетельствовании были понятые или применялась видеозапись?', answer: 4, valueAnswerTrue: 'Да', valueAnswerFalse: 'Нет', id: '4' },
        { value: 'Списаны ли деньги с карты?', answer: 5, valueAnswerTrue: 'Да', valueAnswerFalse: 'Нет', id: '5' }
      ],
      questions4: [
        { value: 'Вы действительно передавали управление автомобилем другому лицу?', answer: 1, valueAnswerTrue: 'Да', valueAnswerFalse: 'Нет', id: '1' },
        { value: 'У компании каршеринга есть какие-либо доказательства передачи управления?', answer: 2, valueAnswerTrue: 'Да', valueAnswerFalse: 'Нет', id: '2' },
        { value: 'У вас есть доказательства того, что не передавали руль?', answer: 3, valueAnswerTrue: 'Да', valueAnswerFalse: 'Нет', id: '3' },
        { value: 'Получили претензию от компании каршеринга?', answer: 4, valueAnswerTrue: 'Да', valueAnswerFalse: 'Нет', id: '4' },
        { value: 'Списаны ли деньги с карты?', answer: 5, valueAnswerTrue: 'Да', valueAnswerFalse: 'Нет', id: '5' }
      ],
      questions5: [
        { value: 'Вынесено постановление за неправильную парковку?', answer: 1, valueAnswerTrue: 'Да', valueAnswerFalse: 'Нет', id: '1' },
        { value: 'В деле есть доказательства парковки в неположенном месте?', answer: 2, valueAnswerTrue: 'Да', valueAnswerFalse: 'Нет', id: '2' },
        { value: 'Получили претензию от компании каршеринга?', answer: 3, valueAnswerTrue: 'Да', valueAnswerFalse: 'Нет', id: '3' },
        { value: 'Списаны ли деньги с карты?', answer: 4, valueAnswerTrue: 'Да', valueAnswerFalse: 'Нет', id: '4' }
      ],
      result: [],
      name: null,
      email: null,
      phone: null,
      isPopup: false,
      title: null,
      subtitle: null
    }
  },
  computed: {
    numArticle () {
      return this.$route.params.id
    }
  },
  created () {
    Inputmask().mask(document.querySelectorAll('input[type=text]'))
  },
  methods: {
    removePopup () {
      this.isPopup = false
    },
    outMessUrist (e) {
      if (!this.name) {
        e.target.innerText = 'Вам необходимо указать как вас зовут'
        e.target.classList.add('error-send')
        this.isPopup = true
        this.title = 'Вам необходимо указать как вас зовут'
        this.subtitle = 'Пожалуйста, укажите Ваше имя. Это необходимо для более качественного предоставления услуг'
        setTimeout(() => {
          e.target.innerText = 'Узнать результаты'
          e.target.classList.remove('error-send')
        }, 800)
      } else if (!this.phone) {
        e.target.innerText = 'Вам необходимо указать email или телефон'
        e.target.classList.add('error-send')
        this.isPopup = true
        this.title = 'Вам необходимо указать Ваш номер телефона'
        this.subtitle = 'Пожалуйста, укажите Ваш телефон. Это необходимо для более качественного предоставления услуг. Ваши данные надежно защищены'
        setTimeout(() => {
          e.target.innerText = 'Узнать результаты'
          e.target.classList.remove('error-send')
        }, 800)
      } else {
        const vm = this
        vm.result.join()
        if (!this.name) e.target.innerText = 'Вам необходимо указать как вас зовут'
        else if (!this.phone && !this.email) e.target.innerText = 'Вам необходимо указать email или телефон'
        else {
          const reg = '^\\+\\d{1,3}\\s?\\(\\d{3}\\)\\s?\\d{3}(-\\d{2}){2}$' // для проверки правильности введенного номера
          if (this.phone.match(reg) !== null) {
            e.target.innerText = 'Ждите..'
            const formData = new FormData()
            formData.append('crm', '11')
            formData.append('pipe', '22')
            formData.append('contact[name]', this.name)
            formData.append('contact[199]', this.phone)
            formData.append('contact[200]', this.email)

            // для chance.avtourist.info
            // formData.append('lead[214]', 'ЕБСА')
            // formData.append('note', 'Заявка с chance.avtourist.info по статье ' + this.numArticle + '<br>' + this.result.join())

            // для mychance.avtourist.info
            formData.append('lead[214]', 'carsharing.avtourist.info')
            formData.append('note', 'Заявка с carsharing.avtourist.info по статье ' + (this.numArticle === 1 ? 'ДТП' : this.numArticle === 2 ? 'Опасное вождение' : this.numArticle === 3 ? 'Упр-ие в нетрезвом виде' : this.numArticle === 4 ? 'Передача руля' : this.numArticle === 5 ? 'Неправ. парковка' : 'другое') + '<br>' + this.result.join())

            axios.post('https://urist.v-avtoservice.com/api/import-lead',
              formData,
              {
                headers: {
                  'Content-Type': 'multipart/form-data'
                }
              }
            ).then(res => {
              e.target.innerText = 'Заявка отправлена!'
              document.getElementById('modalSubscribe').style.display = 'block'
              document.getElementById('answer').style.display = 'block'
              this.$refs.form.remove()
            })
          } else {
            this.isPopup = true
            this.title = 'Номер не корректный!'
            this.subtitle = 'Пожалуйста, укажите правильно номер вашего телефона. Ваши данные надежно защищены.'
          }
        }
      }
    }
  },
  mounted () {
    Inputmask().mask(document.querySelectorAll('input[type=text]'))
  }
}
</script>

<style lang="scss" scoped>
  .v-services_articles {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    h2 {
      flex-basis: 100%;
      font-weight: bold;
      font-size: 28px;
      line-height: 34px;
    }
    p {
      flex-basis: 100%;
      color: rgba(0, 0, 0, 0.4);
    }
    .articles-container {
      cursor: pointer;
      width: 198px;
      margin: 10px;
      padding: 10px 0 0 10px;
      background: rgba(32, 209, 82, 0.08);
      border: 1px solid rgba(32, 209, 82, 0.16);
      box-sizing: border-box;
      border-radius: 10px;
      position: relative;
      &::before {
        content: '';
        width: 25px;
        height: 15px;
        bottom: 10px;
        right: 10px;
        position: absolute;
        background: url("../assets/img/icons/arrow.svg") center no-repeat;
      }
      .title {
        margin-bottom: 15px;
        font-weight: 600;
        font-size: 20px;
        line-height: 28px;
        color: #20D152;
      }
      &.active .title {
        color: #FFFFFF;
      }
      &.active {
        background: #20D152;
        color: #FFFFFF;
      }
    }
  }
  .v-services_quest {
    margin-bottom: 45px;
    .v-services_quest-container {
      display: flex;
      align-items: center;
      padding: 2px 2px 2px 15px;
      margin: 10px 10px;
      height: 40px;
      border-radius: 3px;
      border: 1px solid #E0E0E0;
      span {
        max-width: 50%;
      }
      .answer-wrapper {
        margin-left: auto;
        label {
          display: inline-flex;
          justify-content: center;
          align-items: center;
          min-width: 120px;
          width: 200px;
          height: 38px;
          background: rgba(32, 209, 82, 0.08);
          margin: 0 2px;
          border-radius: 2px;
          border: 1px solid #E0E0E0;
          transition-duration: .3s;
          cursor: pointer;
          &.active {
            color: #FFFFFF;
            background: #20D152;
          }
          &:active {
            transform: scale(0.8);
          }
        }
      }
    }
  }
  .v-services_form {
    .title {
      margin-bottom: 25px;
      font-weight: bold;
      font-size: 28px;
      line-height: 34px;
    }
    form {
      display: flex;
      flex-direction: column;
      justify-content: center;
      .form-wrapper {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        margin-bottom: 24px;
        label {
          margin: 10px 5px;
          position: relative;
          max-width: 30%;
          min-width: 200px;
          width: 30%;
          &:focus {
            color: #0F8831;
          }
          input {
            width: 100%;
            border: 1px solid #20D152;
            box-sizing: border-box;
            border-radius: 6px;
            height: 48px;
            padding: 20px 14px;
            &:focus {
              color: #0F8831;
            }
          }
          span {
            position: absolute;
            top: -15px;
            left: 5px;
            font-weight: 600;
            font-size: 12px;
            line-height: 15px;
          }
        }
      }
    }
    button {
      display: block;
      width: 220px;
      height: 48px;
      background: #20D152;
      margin: 0 auto;
      box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.15);
      border-radius: 6px;
      color: #FFFFFF;
      font-weight: bold;
      font-size: 16px;
    }
    p {
      text-align: center;
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
      color: rgba(0, 0, 0, 0.36);
    }
  }
  .v-services_answer {
    margin-bottom: 50px;
    .answer-wrapper {
      padding: 15px;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.04), rgba(0, 0, 0, 0.04)), #FFFFFF;
      border: 1px solid rgba(0, 0, 0, 0.12);
      box-sizing: border-box;
      border-radius: 16px;
      .answer-container {
        margin: 15px 0;
        background: #FFFFFF;
        padding: 15px;
        box-shadow: 0 0 10px 2px rgba(221, 221, 221, 1);
        box-sizing: border-box;
        border-radius: 8px;
        .title {
          margin-bottom: 10px;
          font-weight: bold;
          font-size: 20px;
          line-height: 24px;
          text-transform: uppercase;
        }
      }
    }
  }
  @media (max-width: 1110px) {
    .v-services_quest {
      .v-services_quest-container {
        display: flex;
        flex-wrap: wrap;
        height: initial;
        padding: 10px;
        span {
          margin-bottom: 10px;
          max-width: initial;
        }
        .answer-wrapper {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          label {
          }
        }
      }
    }
  }
  @media (max-width: 450px) {
    .v-services_articles {
      margin-bottom: 30px;
      h2 {
        font-size: 22px;
        line-height: 26px;
      }
      .articles-container {
        width: calc(100% - 30px);
        height: 110px;
        margin: 5px;
        .title {
          margin-bottom: 7px;
        }
      }
    }
    .v-services_form {
      .title {
      }
      form {
        display: flex;
        flex-direction: column;
        justify-content: center;
        .form-wrapper {
          label {
            margin: 10px 5px;
            position: relative;
            max-width: initial;
            min-width: 200px;
            width: 100%;
          }
        }
      }
      button {
        display: block;
        width: 220px;
        height: 48px;
        background: #20D152;
        margin: 0 auto;
        box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.15);
        border-radius: 6px;
        color: #FFFFFF;
        font-weight: bold;
        font-size: 16px;
      }
      p {
        text-align: center;
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
        color: rgba(0, 0, 0, 0.36);
      }
    }
  }
</style>
